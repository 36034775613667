import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, withCtx as _withCtx, Teleport as _Teleport, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9e3d2b6e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 1 }
const _hoisted_2 = { key: 2 }
const _hoisted_3 = {
  key: 1,
  class: "py-3 lg:py-6 h-100 relative"
}
const _hoisted_4 = { class: "flex" }
const _hoisted_5 = { class: "table w-full" }
const _hoisted_6 = { key: 3 }
const _hoisted_7 = { class: "flex flex-col report-upload-form bg-bg-0" }
const _hoisted_8 = { class: "min-w-[300px] lg:max-w-[80%]" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Treeselect = _resolveComponent("Treeselect")!
  const _component_ReportAttributeTabLoading = _resolveComponent("ReportAttributeTabLoading")!
  const _component_ReportInstanceLoading = _resolveComponent("ReportInstanceLoading")!
  const _component_ReportAttributeTab = _resolveComponent("ReportAttributeTab")!
  const _component_ReportInstanceRow = _resolveComponent("ReportInstanceRow")!
  const _component_Pagination = _resolveComponent("Pagination")!
  const _component_AssetReportForm = _resolveComponent("AssetReportForm")!
  const _component_GenericCardShell = _resolveComponent("GenericCardShell")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isInReports && _ctx.assets.length)
      ? (_openBlock(), _createBlock(_component_Treeselect, {
          key: 0,
          onOpen: _ctx.rotateArrow,
          onClose: _ctx.rotateArrow,
          "onUpdate:modelValue": [
            _ctx.selectAsset,
            _cache[0] || (_cache[0] = ($event: any) => ((_ctx.modelValue) = $event))
          ],
          options: _ctx.assets,
          multiple: false,
          normalizer: _ctx.normalizer,
          searchable: true,
          alwaysOpen: false,
          limit: 1,
          valueConsistsOf: 'BRANCH_PRIORITY',
          "disable-branch-nodes": false,
          valueFormat: 'label',
          class: "reportsAssetSelect max-w-[250px] mb-4",
          modelValue: _ctx.modelValue,
          id: _ctx.TreeselectId
        }, null, 8, ["onOpen", "onClose", "onUpdate:modelValue", "options", "normalizer", "modelValue", "id"]))
      : _createCommentVNode("", true),
    (this.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_ReportAttributeTabLoading),
          _createVNode(_component_ReportInstanceLoading)
        ]))
      : (_ctx.report && _ctx.report.attributes.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_ReportAttributeTab, {
              attributes: _ctx.report.attributes,
              onUpdateSelectedAttribute: _ctx.updateSelectedAttribute
            }, null, 8, ["attributes", "onUpdateSelectedAttribute"]),
            (_ctx.loadingInstance)
              ? (_openBlock(), _createBlock(_component_ReportInstanceLoading, { key: 0 }))
              : (_ctx.instances.length > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createElementVNode("div", _hoisted_4, [
                      _createElementVNode("table", _hoisted_5, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.instances, (item, i) => {
                          return (_openBlock(), _createBlock(_component_ReportInstanceRow, {
                            key: i,
                            item: item,
                            onDownload: ($event: any) => (_ctx.download(item))
                          }, null, 8, ["item", "onDownload"]))
                        }), 128))
                      ])
                    ])
                  ]))
                : _createCommentVNode("", true),
            (!_ctx.loading && _ctx.totalPages > 1)
              ? (_openBlock(), _createBlock(_component_Pagination, {
                  key: 2,
                  currentPage: _ctx.currentPage,
                  totalPages: _ctx.totalPages,
                  numberOfButtonAfterCurrent: 4,
                  onSelectPage: _cache[1] || (_cache[1] = selectedPage => _ctx.fetchReports(selectedPage - 1))
                }, null, 8, ["currentPage", "totalPages"]))
              : _createCommentVNode("", true)
          ]))
        : (!_ctx.report.attributes.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, "No reports found for this asset/category"))
          : _createCommentVNode("", true),
    (_ctx.showForm)
      ? (_openBlock(), _createBlock(_Teleport, {
          key: 4,
          to: "body"
        }, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_GenericCardShell, null, {
                default: _withCtx(() => [
                  _createVNode(_component_AssetReportForm, {
                    id: _ctx.report.id,
                    title: _ctx.report.name,
                    assets: _ctx.assets,
                    assetId: _ctx.selectedAsset?.id,
                    onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.closeForm()))
                  }, null, 8, ["id", "title", "assets", "assetId"])
                ]),
                _: 1
              })
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}