import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-15e33882"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "absolute w-8.5 h-5.25 fleet-card__fav2 top-3 -right-1.5"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Star = _resolveComponent("Star")!
  const _component_LoaderIcon = _resolveComponent("LoaderIcon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["absolute w-8.5 h-5.25 top-3 -right-2 z-3", { 'fleet-card__fav': _ctx.isFav }])
    }, [
      (!_ctx.loading)
        ? (_openBlock(), _createBlock(_component_Star, {
            key: 0,
            fill: _ctx.isFav ? _ctx.fillFav : _ctx.fill,
            onClick: _ctx.changeFavStatus,
            class: "cursor-pointer",
            stroke: _ctx.isFav ? _ctx.strokeFav : _ctx.stroke
          }, null, 8, ["fill", "onClick", "stroke"]))
        : (_openBlock(), _createBlock(_component_LoaderIcon, { key: 1 }))
    ], 2),
    (_ctx.isFav)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1))
      : _createCommentVNode("", true)
  ], 64))
}