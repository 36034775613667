import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-315be22f"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 2,
  class: "text-white h-full flex items-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomMultiSelect = _resolveComponent("CustomMultiSelect")!
  const _component_treeselect = _resolveComponent("treeselect")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex flex-wrap gap-4", { 'pointer-events-none': _ctx.oneLoading}])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filters, (filter, index) => {
      return (_openBlock(), _createElementBlock("div", { key: index }, [
        (
          (filter.type === 'mono' ||
            filter.type === 'Single' ||
            filter.type === 1) &&
            _ctx.monoOptions &&
            !_ctx.error[index]
        )
          ? (_openBlock(), _createBlock(_component_CustomMultiSelect, {
              key: 0,
              options: _ctx.monoOptions[index],
              placeholder: filter.name,
              default: _ctx.defaultFilter[index],
              class: "select w-45",
              onInput: ($event: any) => (_ctx.updateFiltersList(index, filter.id, 'mono', $event))
            }, null, 8, ["options", "placeholder", "default", "onInput"]))
          : _createCommentVNode("", true),
        (
          (filter.type === 'multi' ||
            filter.type === 2 ||
            filter.type === 'Multi') &&
            !_ctx.error[index] &&
            _ctx.multiSelected[index - 1]
        )
          ? (_openBlock(), _createBlock(_component_treeselect, {
              key: 1,
              "onUpdate:modelValue": [($event: any) => (
          _ctx.updateFiltersList(index, filter.id, 'multi', _ctx.multiSelected[index])
        ), ($event: any) => ((_ctx.multiSelected[index]) = $event)],
              modelValue: _ctx.multiSelected[index],
              multiple: true,
              options: _ctx.multiOptions[index],
              alwaysOpen: false,
              placeholder: `All ${filter.name}`,
              clearOnSelect: true,
              "disable-branch-nodes": false,
              "max-height": 200,
              clearable: true,
              searchable: true,
              limit: 1,
              valueConsistsOf: 'LEAF_PRIORITY',
              class: _normalizeClass(["select custom-select w-70", { 'rotate-x-180': _ctx.openMulti[index] }]),
              onOpen: ($event: any) => (_ctx.rotateArrow(index)),
              onClose: ($event: any) => (_ctx.rotateArrow(index))
            }, null, 8, ["onUpdate:modelValue", "modelValue", "options", "placeholder", "class", "onOpen", "onClose"]))
          : _createCommentVNode("", true),
        (_ctx.error[index])
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.error[index]) + _toDisplayString(filter.name), 1))
          : _createCommentVNode("", true)
      ]))
    }), 128))
  ], 2))
}