import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9f1c3996"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex flex-col h-full relative text-gray-200" }
const _hoisted_2 = {
  key: 0,
  class: "h-full flex flex-col lg:flex-row p-2"
}
const _hoisted_3 = { class: "h-full flex w-full flex-col" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = {
  key: 0,
  class: "font-bold text-sm"
}
const _hoisted_6 = {
  key: 0,
  class: "text-xs font-normal"
}
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = {
  key: 1,
  class: "h-full flex"
}
const _hoisted_9 = { class: "m-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Highcharts = _resolveComponent("Highcharts")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.error)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", {
              class: _normalizeClass(["text-xs whitespace-nowrap", {
            'skeleton w-4/5 xs:w-3/5 mb-2': _ctx.loading,
            'w-full': !_ctx.loading,
            'mb-0.5': _ctx.kpiTitle,
          }])
            }, [
              (
              (_ctx.previewType === 'KPI' || _ctx.previewType === 'ChartAndKPI') &&
                !_ctx.loading &&
                _ctx.kpiDetails &&
                _ctx.kpiDetails.kpis.length === 1
            )
                ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.kpiTitle), 1))
                : _createCommentVNode("", true)
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass(["text-xl", {
            'skeleton w-4/5 xs:w-3/5': _ctx.loading,
            'w-full': !_ctx.loading,
          }])
            }, [
              (
              (_ctx.previewType === 'KPI' || _ctx.previewType === 'ChartAndKPI') &&
                !_ctx.loading &&
                _ctx.kpiDetails
            )
                ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.kpiDetails.kpis, (kpi, i) => {
                      return (_openBlock(), _createElementBlock("div", { key: i }, [
                        (kpi.label)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(kpi.label), 1))
                          : _createCommentVNode("", true),
                        _createElementVNode("span", null, _toDisplayString(kpi.value) + " " + _toDisplayString(kpi.unit), 1)
                      ]))
                    }), 128))
                  ]))
                : _createCommentVNode("", true)
            ], 2),
            (_ctx.graphType && !_ctx.loading && !_ctx.error)
              ? (_openBlock(), _createBlock(_component_Highcharts, {
                  key: 0,
                  options: _ctx.chartOptions,
                  class: _normalizeClass(
            _ctx.graphType === 'PIE' ? 'w-full' : 'w-full mx-[-0.5rem] absolute bottom-0 rounded-b-xl'
          )
                }, null, 8, ["options", "class"]))
              : _createCommentVNode("", true),
            (_ctx.previewType === 'Custom' && !_ctx.loading)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  class: "h-4/6 flex",
                  innerHTML: _ctx.customDetails
                }, null, 8, _hoisted_7))
              : _createCommentVNode("", true)
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.error), 1)
        ]))
  ]))
}