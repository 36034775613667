import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w-full"
}
const _hoisted_2 = {
  key: 1,
  class: "h-full w-full"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ServicesCardKpiShell = _resolveComponent("ServicesCardKpiShell")!
  const _component_ServicesCardChart = _resolveComponent("ServicesCardChart")!
  const _component_Observer = _resolveComponent("Observer")!

  return (_openBlock(), _createBlock(_component_Observer, {
    class: _normalizeClass(["", 
      (_ctx.previewType === 'KPI' || _ctx.previewType === 'Kpi') && !_ctx.fromList
        ? 'w-full'
        : 'h-full'
    ]),
    onOnChange: _ctx.loadDetails
  }, {
    default: _withCtx(() => [
      (_ctx.fromList || _ctx.fromFleet)
        ? (_openBlock(), _createBlock(_resolveDynamicComponent(
        _ctx.fromList && _ctx.observed
          ? 'ServicesCardPreview'
          : !_ctx.fromList && _ctx.fromFleet && _ctx.observed
            ? 'ServicesCardFleet'
            : ''
      ), {
            key: 0,
            id: _ctx.id,
            title: _ctx.title,
            subtitle: _ctx.subtitle,
            "button-text": 'Open',
            "graph-type": _ctx.graphType,
            "preview-type": _ctx.previewType,
            "kpi-title": _ctx.kpiTitle,
            size: _ctx.size,
            "preview-infos": _ctx.previewInfos,
            period: _ctx.period
          }, null, 8, ["id", "title", "subtitle", "graph-type", "preview-type", "kpi-title", "size", "preview-infos", "period"]))
        : _createCommentVNode("", true),
      (!_ctx.fromList && !_ctx.fromFleet && _ctx.observed)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass(
        (_ctx.previewType === 'KPI' || _ctx.previewType === 'Kpi') && _ctx.observed
          ? 'w-full'
          : 'h-full w-full'
      )
          }, [
            ((_ctx.previewType === 'KPI' || _ctx.previewType === 'Kpi') && _ctx.observed)
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  _createVNode(_component_ServicesCardKpiShell, {
                    "is-preview": false,
                    kpis: _ctx.previewInfos
                  }, null, 8, ["kpis"])
                ]))
              : _createCommentVNode("", true),
            (_ctx.previewType === 'Chart' && _ctx.observed)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createVNode(_component_ServicesCardChart, {
                    title: _ctx.title,
                    "graph-type": _ctx.previewInfos.type,
                    id: _ctx.id,
                    "compatible-filters": _ctx.previewInfos.compatibleFilters,
                    description: _ctx.previewInfos.description
                  }, null, 8, ["title", "graph-type", "id", "compatible-filters", "description"])
                ]))
              : _createCommentVNode("", true)
          ], 2))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["onOnChange", "class"]))
}