
import { ILoginService } from '@/core/services/authentication/ILogin.service'
import { defineComponent } from 'vue'
import toaster from '@/map/views/components/misc/toaster.vue'
import { sticky } from '@/map/core/utilities'
import { LocationQuery } from 'vue-router'
import fleetPopover from '@/views/popover/fleet-period.vue'

export default defineComponent({

  inject: ['ILoginService'],
  components: {
    toaster,
    fleetPopover
  },
  watch: {
    '$route.query': {
      immediate: true,
      handler (value:LocationQuery) {
        if (value.modal) {
          const scrollBarWidth = window.innerWidth - document.documentElement.clientWidth
          document.body.style.overflowY = 'hidden'
          if (scrollBarWidth > 0 && document.body.scrollHeight > document.body.clientHeight) {
            document.body.style.paddingRight = scrollBarWidth + 'px'
          }
        } else {
          document.body.style.overflowY = 'auto'
          document.body.style.paddingRight = '0px'
        }
        if (value.popover) {
          window.addEventListener('resize', this.movePosition)
          setTimeout(() => {
            this.movePosition()
          }, 1)
        } else {
          window.removeEventListener('resize', this.movePosition)
        }
      }
    }
  },
  data (): { ILoginService?: ILoginService } {
    return {}
  },
  methods: {
    async isConnected (): Promise<boolean> {
      return await this.ILoginService?.IsConnected() ?? false
    },
    movePosition ():void {
      if (this.$route.query.popover) {
        // @ts-ignore
        sticky(this.$route.query.popover, this.$route.query.id)
      }
    }
  }
})
