import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3093fad4"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "relative",
  ref: "categoryWrapper"
}
const _hoisted_2 = {
  key: 1,
  class: "flex gap-4 w-full custom-scrollbar overflow-x-auto pb-2"
}
const _hoisted_3 = {
  key: 2,
  class: "absolute w-full h-full z-[10] bottom-0 hidden md:block pointer-events-none",
  ref: "arrows"
}
const _hoisted_4 = {
  class: "h-full w-[120px] absolute arrow-shadow-1 hidden flex-col justify-center left-0 lg:-left-6 items-start",
  ref: "arrowRight"
}
const _hoisted_5 = {
  class: "h-full w-[120px] absolute arrow-shadow-2 flex flex-col justify-center right-0 lg:-right-6 items-end",
  ref: "arrowLeft"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ServicesCardKpi = _resolveComponent("ServicesCardKpi")!
  const _component_RightArrowIcon = _resolveComponent("RightArrowIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.list.length)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "flex gap-4 w-[calc(100%+2.5rem)] -ml-5 md:ml-0 md:w-auto overflow-x-scroll overflow-y-hidden no-scroll relative",
          ref: "category",
          onScroll: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.showOrHideArrows && _ctx.showOrHideArrows(...args)))
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (kpi, i) => {
            return (_openBlock(), _createBlock(_component_ServicesCardKpi, {
              key: i,
              loading: _ctx.loading,
              label: kpi.label,
              title: kpi.title,
              value: kpi.value,
              unit: kpi.unit,
              type: kpi.type,
              description: kpi.description
            }, null, 8, ["loading", "label", "title", "value", "unit", "type", "description"]))
          }), 128))
        ], 544))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.kpis, (kpi, i) => {
            return (_openBlock(), _createBlock(_component_ServicesCardKpi, {
              key: i,
              loading: _ctx.loading
            }, null, 8, ["loading"]))
          }), 128))
        ])),
    (!_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("button", {
              class: "rounded-full p-2.5 bg-bg-2 rotate-180 w-fit pointer-events-auto",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.scrollCategory('right')))
            }, [
              _createVNode(_component_RightArrowIcon, { class: "w-5 h-5 stroke-text-prim" })
            ])
          ], 512),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("button", {
              class: "rounded-full p-2.5 bg-bg-2 w-fit pointer-events-auto",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.scrollCategory('left')))
            }, [
              _createVNode(_component_RightArrowIcon, { class: "w-5 h-5 stroke-text-prim" })
            ])
          ], 512)
        ], 512))
      : _createCommentVNode("", true)
  ], 512))
}