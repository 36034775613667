import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2ceb4d43"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex flex-col h-full relative" }
const _hoisted_2 = { class: "h-full flex flex-col w-full m-auto p-4" }
const _hoisted_3 = {
  key: 1,
  class: "h-4/6 flex"
}
const _hoisted_4 = { class: "m-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Highcharts = _resolveComponent("Highcharts")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.loading ? 'h-1/6' : 'h-0')
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["my-10 mx-auto z-20 absolute left-1/2 -translate-x-1/2 text-xl text-center font-bold", _ctx.loading ? 'skeleton w-4/5 max-w-xs' : 'w-fit'])
      }, null, 2)
    ], 2),
    (!_ctx.error)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["flex flex-col lg:flex-row", _ctx.loading ? 'h-5/6' : 'h-full'])
        }, [
          _createElementVNode("div", _hoisted_2, [
            (_ctx.graphType && !_ctx.loading && !_ctx.error)
              ? (_openBlock(), _createBlock(_component_Highcharts, {
                  key: 0,
                  options: _ctx.chartOptions
                }, null, 8, ["options"]))
              : _createCommentVNode("", true)
          ])
        ], 2))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.error), 1)
        ]))
  ]))
}