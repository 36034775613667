import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-55033cad"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "w-4/5" }
const _hoisted_2 = {
  key: 1,
  class: "skeleton"
}
const _hoisted_3 = {
  key: 2,
  class: ""
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Treeselect = _resolveComponent("Treeselect")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.departments.length && _ctx.modelValue)
      ? (_openBlock(), _createBlock(_component_Treeselect, {
          key: 0,
          "onUpdate:modelValue": [
            _ctx.departmentSelection,
            _cache[0] || (_cache[0] = ($event: any) => ((_ctx.modelValue) = $event))
          ],
          options: _ctx.departments,
          searchable: true,
          limit: 1,
          valueConsistsOf: 'BRANCH_PRIORITY',
          clearable: false,
          "disable-branch-nodes": false,
          normalizer: _ctx.normalizer,
          modelValue: _ctx.modelValue,
          onOpen: _ctx.rotateArrow,
          onClose: _ctx.rotateArrow,
          id: "departmentSelect"
        }, null, 8, ["onUpdate:modelValue", "options", "normalizer", "modelValue", "onOpen", "onClose"]))
      : (_ctx.loading && !_ctx.error)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2))
        : (!_ctx.loading && _ctx.error)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.error), 1))
          : _createCommentVNode("", true)
  ]))
}