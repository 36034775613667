import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e01f68a0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex items-center px-6 pb-4" }
const _hoisted_2 = {
  class: "mr-20",
  style: {"flex":"1"}
}
const _hoisted_3 = { class: "mb-1.5" }
const _hoisted_4 = { class: "body-1 body-alt" }
const _hoisted_5 = { class: "rounded-full p-2 bg-bg-2 hover:bg-bg-3 w-fit cursor-pointer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FilesIcon = _resolveComponent("FilesIcon")!
  const _component_CrossIcon = _resolveComponent("CrossIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_FilesIcon, { class: "w-8 h-8 fill-gray-200 mr-3" }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h4", _hoisted_3, " Upload - " + _toDisplayString(_ctx.title), 1),
      _createElementVNode("div", _hoisted_4, " Client/Department - " + _toDisplayString(_ctx.ClientDepartment), 1)
    ]),
    _createElementVNode("span", _hoisted_5, [
      _createVNode(_component_CrossIcon, {
        class: "w-8 h-8 fill-gray-200",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.close()))
      })
    ])
  ]))
}