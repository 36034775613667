
import { computed, defineComponent, PropType, ref } from 'vue'
import DashboardIcon from '@/components/icons/DashboardIcon.vue'
import FilesIcon from '@/components/icons/FilesIcon.vue'
import FleetIcon from '@/components/icons/FleetIcon.vue'
import HomeIcon from '@/components/icons/HomeIcon.vue'
import MapIcon from '@/components/icons/MapIcon.vue'
import AnalyticsIcon from '@/components/icons/AnalyticsIcon.vue'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'NavigationLinkPrimary',
  components: {
    HomeIcon,
    DashboardIcon,
    FilesIcon,
    FleetIcon,
    MapIcon,
    AnalyticsIcon
  },
  props: {
    to: String,
    query: {
      type: Object as PropType<Record<string, string>>,
      required: false
    }
  },
  setup (props) {
    const route = useRoute()
    const hideBar = ref(false)
    const stroke = ['']
    const fill = ['home', 'map']
    const activeLink = computed(() => {
      return (
        route.name === props.to ||
        (route.name === 'analyticsDetail' && props.to === 'analytics') ||
        (route.name === 'assetDetail' && props.to === 'fleet')
      )
    })
    const icon = computed(() => {
      switch (props.to) {
        case 'home':
          return 'HomeIcon'
        case 'map':
          return 'MapIcon'
        case 'fleet':
          return 'FleetIcon'
        case 'analytics':
          return 'AnalyticsIcon'
        case 'allReports':
          return 'FilesIcon'
        case 'allDashboards':
          return 'DashboardIcon'
        default:
          return ''
      }
    })
    return { hideBar, stroke, fill, activeLink, icon }
  }
})
